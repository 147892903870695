<template>
  <div class="container">
    <el-row class="row shadow">
      <el-col :span="12"> <TimeZone @change="dataChange" @changeDateZone="selectDateZone"></TimeZone></el-col>
      <el-col :span="12">
        <el-select
          class="app-selector"
          v-model="appName"
          filterable
          placeholder="Please choose app protocol"
          @change="appChange"
        >
          <el-option v-for="item in appOption" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12" class="col line shadow">
        <Chart
          chartId="seco2nd_line"
          :option="
            new LineOption({ title: `Traffic of App ${appName} (Kbps)`, yunit: 'Kbps' }).getOption([
              'title',
              'tooltip',
              'xAxis',
              'yAxis',
              'grid',
              'series',
            ])
          "
          :series="byteCountLineData"
        ></Chart>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12" class="col line shadow">
        <Chart
          chartId="se3cond_line"
          :option="
            new LineOption({ title: `Traffic of App ${appName} (pps)` }).getOption([
              'title',
              'tooltip',
              'xAxis',
              'grid',
              'yAxis',
              'series',
            ])
          "
          :series="packetCountLineData"
        ></Chart>
      </el-col>
    </el-row>
    <div class="table-box shadow">
      <PaginationTable
        :loading="loading"
        :originData="appData.app_info ? appData.app_info : []"
        :showRule="showRule"
        :rowClick="rowClick"
        :defaultOrder="{ prop: 'sum_byte', order: 'descending' }"
      ></PaginationTable>
      <!-- :routeQuery="{ date: defalutdateArray }" -->
    </div>
  </div>
</template>

<script>
import TimeZone from '@/components/TimeZone'
import DynamicRoute from '@/components/DynamicRoute'
import NumberCol from '@/components/NumberCol'
import { getStatisticApp, getStatisticAppLine, getAppList } from '@/api/AppApi.js'
import { AppViewLineOption } from '@/templates/chart/lineTemplate'

export default {
  name: 'AppView',
  components: {
    TimeZone,
    // Chart,
    // PaginationTable,
  },
  created() {
    this.ipVersion = localStorage.getItem('ip_version') || 'nf'
    // if (this.$route.query.date) {
    //   const date = this.$route.query.date
    //   // this.defalutdateArray = date
    // }
    // this.getAppList()
    if (this.$route.params.appName) {
      this.appName = this.$route.params.appName
    }
    // console.debug(this)
  },
  data() {
    return {
      loading: false,
      ipVersion: 'nf',
      LineOption: AppViewLineOption,
      appName: '',
      showRule: [
        {
          prop: 'customer_ip',
          label: 'Client IP',
          subComponent: DynamicRoute,
          rowFormat: row => {
            return [`/client_view/${row.customer_ip}`, row.customer_ip]
          },
        },
        {
          prop: 'conn_count',
          label: 'Flow Amount',
          sortable: 'custom',
          compare: (a, b) => a.conn_count - b.conn_count,
          subComponent: NumberCol,
          rowFormat: row => {
            return row.conn_count
          },
        },
        {
          prop: 'sum_packet',
          label: 'Packet',
          sortable: 'custom',
          compare: (a, b) => a.sum_packet - b.sum_packet,
          subComponent: NumberCol,
          rowFormat: row => {
            return row.sum_packet
          },
        },
        {
          prop: 'sum_byte',
          label: 'Byte',
          sortable: 'custom',
          compare: (a, b) => a.sum_byte - b.sum_byte,
          subComponent: NumberCol,
          rowFormat: row => {
            return row.sum_byte
          },
        },
        {
          prop: 'Src_to_Dst_Packets',
          label: 'Client to Server Packets',
          sortable: 'custom',
          width: '130px',
          // fixed: 'right',
          compare: (a, b) => a.Src_to_Dst_Packets - b.Src_to_Dst_Packets,
          subComponent: NumberCol,
          rowFormat: row => {
            return row.Src_to_Dst_Packets
          },
        },
        {
          prop: 'Src_to_Dst_Bytes',
          label: 'Client to Server Bytes',
          sortable: 'custom',
          width: '130px',
          // fixed: 'right',
          compare: (a, b) => a.Src_to_Dst_Bytes - b.Src_to_Dst_Bytes,
          subComponent: NumberCol,
          rowFormat: row => {
            return row.Src_to_Dst_Bytes
          },
        },
        {
          prop: 'Dst_to_Src_Packets',
          label: 'Server to Client Packets',
          sortable: 'custom',
          width: '130px',
          // fixed: 'right',
          compare: (a, b) => a.Dst_to_Src_Packets - b.Dst_to_Src_Packets,
          subComponent: NumberCol,
          rowFormat: row => {
            return row.Dst_to_Src_Packets
          },
        },
        {
          prop: 'Dst_to_Src_Bytes',
          label: 'Server to Client Bytes',
          sortable: 'custom',
          width: '130px',
          // fixed: 'right',
          compare: (a, b) => a.Dst_to_Src_Bytes - b.Dst_to_Src_Bytes,
          subComponent: NumberCol,
          rowFormat: row => {
            return row.Dst_to_Src_Bytes
          },
        },
      ],
      flowCountLineData: [],
      byteCountLineData: [],
      packetCountLineData: [],
      dateArray: [],
      // defalutdateArray: [],
      appData: {},
      appDataLine: {},
      appOption: [],
      DataZone: parseInt(new Date().getTimezoneOffset() / 60),
    }
  },
  mounted() {
    // this.getAppList()
  },
  props: {},
  methods: {
    async getAppList() {
      const appList = await getAppList(this.ipVersion, ...this.dateArray)
      this.appOption = appList.map((app, index) => {
        return {
          value: index,
          label: app,
        }
      })
      if (this.appOption.length) {
        if (!this.$route.params.appName) {
          this.appName = this.appOption[0].label
        }
      }
    },
    async getStatisticApp() {
      this.loading = true
      this.appData = await getStatisticApp(this.ipVersion, this.appName, ...this.dateArray)
      this.loading = false
    },
    async getStatisticAppLine() {
      this.appDataLine = await getStatisticAppLine(this.ipVersion, this.appName, ...this.dateArray)
      this.handleMintueLine('flowCountLineData', 'flow_count_result')
      this.handleMintueLine('byteCountLineData', 'byte_count_result')
      this.handleMintueLine('packetCountLineData', 'packet_count_result')
    },
    handleMintueLine(localDataListName, listName) {
      this[localDataListName] = this.appDataLine[listName].map(item => {
        item.type = 'line'
        item.data = item.data.map(this.transformDateWithTimeZone)
        item.showSymbol = item.data.length < 10 ? true : false
        return item
      })
      // 已知错误条件：只有一组数据，数据值一样，数据值小于1
      //为了防止折线图错误，先对数据值进行去重
      if (this[localDataListName][0]) {
        const tempObj = {}
        this[localDataListName][0].data.forEach(d => {
          tempObj[d[1]] = 1
        })
        // 为了防止折线图错误，且数据小于1时折线图错误
        if (Object.keys(tempObj).length === 1) {
          this[localDataListName].push({
            name: 'aa',
            data: [[this[localDataListName][0].data[0], 1]],

            type: 'line',
            showSymbol: false,
          })
        }
      }
    },
    transformDateWithTimeZone(lineitem) {
      lineitem[0] = lineitem[0] - this.DataZone * 60 * 60 * 1000
      return lineitem
    },
    rowClick(row, column, event) {
      if (event.srcElement.nodeName === 'A') {
        return
      }
      this.$router.push({
        path: `/flow_view/`,
        query: {
          // date: this.defalutdateArray,
          appName: this.appName,
          ip: row.customer_ip,
          protocolType: 'App',
        },
      })
    },
    async dataChange(dateArray, DataZone) {
      this.dateArray = dateArray
      this.DataZone = DataZone
      // this.defalutdateArray = [
      //   new Date(parseInt(dateArray[0]) - this.DataZone * 60 * 60 * 1000).toJSON(),
      //   new Date(parseInt(dateArray[1]) - this.DataZone * 60 * 60 * 1000).toJSON(),
      // ]
      await this.getAppList()
      this.getStatisticApp()
      this.getStatisticAppLine()
    },
    async selectDateZone(newDateZone, dateArray) {
      this.DataZone = newDateZone
      this.dateArray = dateArray
      // this.defalutdateArray = [
      //   new Date(parseInt(dateArray[0]) - this.DataZone * 60 * 60 * 1000).toJSON(),
      //   new Date(parseInt(dateArray[1]) - this.DataZone * 60 * 60 * 1000).toJSON(),
      // ]
      await this.getAppList()
      this.getStatisticApp()
      this.getStatisticAppLine()
    },

    appChange(appIndex) {
      this.appName = this.appOption[appIndex].label
      this.getStatisticApp()
      this.getStatisticAppLine()
    },
  },
}
</script>

<style lang="scss" scoped>
.app-selector {
  width: 100%;
}

.col {
  background-color: #fff;
}

.line {
  width: 100%;
  height: 30vh;
  padding: 5px;
}
</style>
